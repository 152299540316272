import { SEARCH_BOX_FIELDS_LAYOUT, SEARCH_BOX_ROW_LAYOUT } from '@config/consts/searchBox/layout/search/imovirtualpt2';
import type { SearchConfig } from '@config/search/type';

export const SEARCH_CONFIG: SearchConfig = {
    isSaveSearchClicktipEnabled: true,
    searchBoxRows: SEARCH_BOX_ROW_LAYOUT,
    searchBoxFieldsLayout: SEARCH_BOX_FIELDS_LAYOUT,
    areLocationObjectBreadcrumbsEnabled: true,
    seo: {
        studioFlatLabels: ['t0'],
        metaDataVariants: ['primaryMarket', 'secondaryMarket'],
        variantForPageTitleOfNumberOfRooms: 'typology',
        variantForDescriptionOfNumberOfRooms: 'typology',
        isCrawlerTextTransformationEnabled: true,
        internalSeoLinks: {
            propertiesForSell: [
                'FLAT',
                'STUDIO_FLAT',
                'HOUSE',
                'TERRAIN',
                'COMMERCIAL_PROPERTY',
                'OFFICE',
                'HALL',
                'GARAGE',
                'VILLAS',
                'PENTHOUSES',
            ],
            propertiesForRent: [
                'FLAT',
                'STUDIO_FLAT',
                'HOUSE',
                'ROOM',
                'TERRAIN',
                'COMMERCIAL_PROPERTY',
                'OFFICE',
                'HALL',
                'GARAGE',
                'VILLAS',
                'PENTHOUSES',
            ],
            propertiesFromPrimaryMarket: [
                'FLAT',
                'STUDIO_FLAT',
                'HOUSE',
                'INVESTMENT',
                'COMMERCIAL_PROPERTY',
                'HALL',
                'GARAGE',
            ],
            estatesWithRelatedNumberOfRoomsSearches: ['FLAT', 'HOUSE'],
        },
        queryParametersEnabledForIndexing: ['page', 'description'],
        isExtendedPageTitleEnabled: true,
    },
    defaultBoundingBoxForMap: {
        neLat: 42.154311,
        neLng: -6.189159,
        swLat: 36.909815,
        swLng: -9.545657,
    },
    vectorMapId: 'eb0940ecf0e99dd2',
    locationLevelLikeCity: ['council'],
    locationLevelLikeDistrictAndSubdistrict: ['parish', 'neighborhood'],
    locationLevelLikeDistrict: ['parish'],
    locationLevelLikeSubdistrict: ['neighborhood'],
    shouldShowSearchCriteriaOnSaveSearchModal: true,
    shouldShowSaveSearchPrompt: true,
    isSpecialOfferForUnitsExperimentEnabled: false,
    isPopularSearchesSectionEnabled: false,
};
