import type { SearchBoxWidgetConfig } from '@config/widgets/searchBox/type';
import { ESTATE } from '@type/search/filters/estate';
import { TRANSACTION } from '@type/search/filters/transaction';

export const SEARCH_BOX_WIDGET_CONFIG: SearchBoxWidgetConfig = {
    fieldsMetadata: {
        /* eslint-disable @typescript-eslint/naming-convention -- Disabled to keep the backward compatibility */
        estate: {
            default: {
                fieldType: 'select',
                payload: {
                    defaultValue: 'HOUSE',
                    options: {
                        DEFAULT: [
                            'FLAT',
                            'STUDIO_FLAT',
                            'HOUSE',
                            'INVESTMENT',
                            'ROOM',
                            'TERRAIN',
                            'COMMERCIAL_PROPERTY',
                            'HALL',
                            'GARAGE',
                        ],
                    },
                    maxMenuHeight: 340, // dropdown by default displays up to 8 items without showing a scrollbar - here we have 9 options
                },
            },
        },
        market: {
            default: {
                fieldType: 'select',
                only: [
                    'FLAT_SELL',
                    'STUDIO_FLAT_SELL',
                    'HOUSE_SELL',
                    'COMMERCIAL_PROPERTY_SELL',
                    'HALL_SELL',
                    'GARAGE_SELL',
                ],
                payload: {
                    defaultValue: 'ALL',
                    options: {
                        DEFAULT: ['ALL', 'PRIMARY', 'SECONDARY'],
                    },
                },
            },
        },
        ownerTypeSingleSelect: {
            default: {
                fieldType: 'select',
                only: [
                    'FLAT_SELL',
                    'STUDIO_FLAT_SELL',
                    'HOUSE_SELL',
                    'COMMERCIAL_PROPERTY_SELL',
                    'HALL_SELL',
                    'GARAGE_SELL',
                ],
                payload: {
                    defaultValue: 'ALL',
                    options: {
                        DEFAULT: ['ALL', 'DEVELOPER', 'AGENCY', 'PRIVATE'],
                    },
                },
            },
        },
        transaction: {
            default: {
                fieldType: 'select',
                payload: {
                    defaultValue: 'SELL',
                    options: {
                        DEFAULT: ['RENT', 'SELL'],
                        INVESTMENT: ['SELL'],
                        ROOM: ['RENT'],
                    },
                },
            },
        },
        distanceRadius: {
            default: {
                fieldType: 'select',
                payload: {
                    defaultValue: '0',
                    options: {
                        DEFAULT: ['0', '5', '10', '15', '25', '50', '75'],
                        METERS: ['100', '200', '300', '500', '750', '1000'],
                    },
                },
            },
        },
        freeFrom: {
            default: {
                fieldType: 'select',
                only: [
                    'FLAT_RENT',
                    'STUDIO_FLAT_RENT',
                    'HOUSE_RENT',
                    'ROOM_RENT',
                    'TERRAIN_RENT',
                    'COMMERCIAL_PROPERTY_RENT',
                    'HALL_RENT',
                    'GARAGE_RENT',
                ],
                payload: {
                    defaultValue: null,
                    options: {
                        DEFAULT: ['NOW', 'THIRTY_DAYS', 'NINETY_DAYS'],
                    },
                },
            },
        },
        investmentEstateType: {
            default: {
                fieldType: 'select',
                only: ['INVESTMENT_SELL'],
                payload: {
                    defaultValue: null,
                    options: {
                        DEFAULT: ['FLATS', 'HOUSES', 'COMMERCIAL_PROPERTIES'],
                    },
                },
            },
        },
        advertId: {
            default: {
                fieldType: 'input',
                payload: {
                    defaultValue: '',
                },
            },
        },
        description: {
            default: {
                fieldType: 'input',
                payload: {
                    defaultValue: '',
                },
            },
        },
        investmentName: {
            default: {
                fieldType: 'input',
                only: ['INVESTMENT_SELL'],
                payload: {
                    defaultValue: '',
                },
            },
        },
        developerName: {
            default: {
                fieldType: 'input',
                only: ['INVESTMENT_SELL'],
                payload: {
                    defaultValue: '',
                },
            },
        },
        price: {
            default: {
                fieldType: 'range',
                payload: {
                    defaultValue: '',
                },
            },
        },
        pricePerMeter: {
            default: {
                fieldType: 'range',
                payload: {
                    defaultValue: '',
                },
            },
        },
        area: {
            default: {
                fieldType: 'range',
                payload: {
                    defaultValue: '',
                },
            },
        },
        roomsNumber: {
            default: {
                fieldType: 'multiCheckbox',
                only: [
                    'FLAT_SELL',
                    'FLAT_RENT',
                    'HOUSE_SELL',
                    'HOUSE_RENT',
                    'INVESTMENT_SELL',
                    'COMMERCIAL_PROPERTY_SELL',
                ],
                payload: {
                    isInline: true,
                    defaultValue: [],
                    options: {
                        DEFAULT: ['ONE', 'TWO', 'THREE', 'FOUR', 'FIVE', 'SIX_OR_MORE'],
                    },
                },
            },
        },
        buildingType: {
            default: {
                fieldType: 'multiSelect',
                payload: {
                    defaultValue: [],
                    options: {
                        FLAT: ['BLOCK', 'TENEMENT', 'HOUSE', 'INFILL', 'RIBBON', 'APARTMENT', 'LOFT'],
                        STUDIO_FLAT: ['BLOCK', 'TENEMENT', 'HOUSE', 'INFILL', 'RIBBON', 'APARTMENT', 'LOFT'],
                        HOUSE: ['DETACHED', 'SEMI_DETACHED', 'RIBBON', 'TENEMENT', 'RESIDENCE', 'FARM'],
                    },
                },
            },
        },
        buildingMaterial: {
            default: {
                fieldType: 'multiSelect',
                only: ['FLAT_SELL', 'STUDIO_FLAT_SELL', 'HOUSE_SELL'],
                payload: {
                    defaultValue: [],
                    options: {
                        DEFAULT: [
                            'BRICK',
                            'WOOD',
                            'BREEZEBLOCK',
                            'HYDROTON',
                            'CONCRETE_PLATE',
                            'CONCRETE',
                            'SILIKAT',
                            'CELLULAR_CONCRETE',
                            'OTHER',
                            'REINFORCED_CONCRETE',
                        ],
                        HOUSE_SELL: [
                            'BRICK',
                            'WOOD',
                            'BREEZEBLOCK',
                            'HYDROTON',
                            'CONCRETE_PLATE',
                            'CONCRETE',
                            'SILIKAT',
                            'CELLULAR_CONCRETE',
                            'OTHER',
                        ],
                    },
                },
            },
        },
        floors: {
            default: {
                fieldType: 'multiSelect',
                only: [
                    'FLAT_SELL',
                    'FLAT_RENT',
                    'STUDIO_FLAT_SELL',
                    'STUDIO_FLAT_RENT',
                    'COMMERCIAL_PROPERTY_SELL',
                    'COMMERCIAL_PROPERTY_RENT',
                ],
                payload: {
                    defaultValue: [],
                    options: {
                        DEFAULT: [
                            'CELLAR',
                            'GROUND',
                            'FIRST',
                            'SECOND',
                            'THIRD',
                            'FOURTH',
                            'FIFTH',
                            'SIXTH',
                            'SEVENTH',
                            'EIGHTH',
                            'NINTH',
                            'TENTH',
                            'ABOVE_TENTH',
                            'GARRET',
                        ],
                    },
                },
            },
        },
        floorsNumber: {
            default: {
                fieldType: 'range',
                payload: {
                    defaultValue: '',
                },
            },
        },
        height: {
            default: {
                fieldType: 'range',
                only: ['HALL_SELL', 'HALL_RENT'],
                payload: {
                    defaultValue: '',
                },
            },
        },
        buildYear: {
            default: {
                fieldType: 'range',
                only: ['FLAT_SELL', 'FLAT_RENT', 'STUDIO_FLAT_SELL', 'STUDIO_FLAT_RENT', 'HOUSE_SELL', 'HOUSE_RENT'],
                payload: {
                    defaultValue: '',
                },
            },
        },
        isPrivateOwner: {
            default: {
                fieldType: 'checkbox',
                payload: {
                    defaultValue: false,
                },
            },
        },
        hasOpenDay: {
            default: {
                fieldType: 'checkbox',
                payload: {
                    defaultValue: false,
                },
            },
        },
        daysSinceCreated: {
            default: {
                fieldType: 'radioGroup',
                payload: {
                    defaultValue: 'ANY',
                    options: {
                        // Note: Radio button has to have the value, that's why there's 'ANY' and not `undefined`.
                        DEFAULT: ['ANY', '1', '3', '7'],
                    },
                },
            },
        },
        hasPhotos: {
            default: {
                fieldType: 'checkbox',
                payload: {
                    defaultValue: false,
                },
            },
        },
        isForStudents: {
            default: {
                fieldType: 'checkbox',
                payload: {
                    defaultValue: false,
                },
            },
        },
        hasMovie: {
            default: {
                fieldType: 'checkbox',
                payload: {
                    defaultValue: false,
                },
            },
        },
        isBungalov: {
            default: {
                fieldType: 'checkbox',
                payload: {
                    defaultValue: false,
                },
            },
        },
        isRecreational: {
            default: {
                fieldType: 'checkbox',
                payload: {
                    defaultValue: false,
                },
            },
        },
        hasWalkaround: {
            default: {
                fieldType: 'checkbox',
                payload: {
                    defaultValue: false,
                },
            },
        },
        hasRemoteServices: {
            default: {
                fieldType: 'checkbox',
                payload: {
                    defaultValue: false,
                },
            },
        },
        extras: {
            default: {
                fieldType: 'multiCheckbox',
                payload: {
                    defaultValue: [],
                    options: {
                        FLAT_SELL: [
                            'BALCONY',
                            'TERRACE',
                            'GARAGE',
                            'BASEMENT',
                            'LIFT',
                            'SEPARATE_KITCHEN',
                            'GARDEN',
                            'TWO_STOREY',
                            'HAS_PHOTOS',
                        ],
                        FLAT_RENT: [
                            'IS_PRIVATE_OWNER',
                            'LIFT',
                            'GARAGE',
                            'AIR_CONDITIONING',
                            'BALCONY',
                            'TERRACE',
                            'HAS_PHOTOS',
                            'INTERNET',
                            'GARDEN',
                            'CABLE_TELEVISION',
                            'SEPARATE_KITCHEN',
                        ],
                        STUDIO_FLAT: [
                            'AIR_CONDITIONING',
                            'BALCONY',
                            'BASEMENT',
                            'GARAGE',
                            'GARDEN',
                            'LIFT',
                            'NON_SMOKERS_ONLY',
                            'SEPARATE_KITCHEN',
                            'TERRACE',
                            'TWO_STOREY',
                            'USABLE_ROOM',
                        ],
                        COMMERCIAL_PROPERTY: [
                            'AIR_CONDITIONING',
                            'ASPHALT_ACCESS',
                            'ELEVATOR',
                            'FURNITURE',
                            'HEATING',
                            'PARKING',
                            'SHOP_WINDOW',
                        ],
                        HOUSE_SELL: ['HAS_PHOTOS'],
                        HOUSE_RENT: ['IS_PRIVATE_OWNER', 'HAS_PHOTOS', 'INTERNET', 'CABLE_TELEVISION'],
                    },
                },
            },
            eure28840b: {
                fieldType: 'multiCheckbox',
                payload: {
                    defaultValue: [],
                    options: {
                        FLAT_SELL: [
                            'BALCONY',
                            'TERRACE',
                            'GARAGE',
                            'BASEMENT',
                            'LIFT',
                            'SEPARATE_KITCHEN',
                            'GARDEN',
                            'TWO_STOREY',
                            'HAS_PHOTOS',
                        ],
                        FLAT_RENT: [
                            'IS_PET_FRIENDLY',
                            'IS_PRIVATE_OWNER',
                            'LIFT',
                            'GARAGE',
                            'AIR_CONDITIONING',
                            'BALCONY',
                            'TERRACE',
                            'HAS_PHOTOS',
                            'INTERNET',
                            'GARDEN',
                            'CABLE_TELEVISION',
                            'SEPARATE_KITCHEN',
                        ],
                        STUDIO_FLAT_SELL: [
                            'AIR_CONDITIONING',
                            'BALCONY',
                            'BASEMENT',
                            'GARAGE',
                            'GARDEN',
                            'LIFT',
                            'NON_SMOKERS_ONLY',
                            'SEPARATE_KITCHEN',
                            'TERRACE',
                            'TWO_STOREY',
                            'USABLE_ROOM',
                        ],
                        STUDIO_FLAT_RENT: [
                            'IS_PET_FRIENDLY',
                            'AIR_CONDITIONING',
                            'BALCONY',
                            'BASEMENT',
                            'GARAGE',
                            'GARDEN',
                            'LIFT',
                            'NON_SMOKERS_ONLY',
                            'SEPARATE_KITCHEN',
                            'TERRACE',
                            'TWO_STOREY',
                            'USABLE_ROOM',
                        ],
                        COMMERCIAL_PROPERTY: [
                            'AIR_CONDITIONING',
                            'ASPHALT_ACCESS',
                            'ELEVATOR',
                            'FURNITURE',
                            'HEATING',
                            'PARKING',
                            'SHOP_WINDOW',
                        ],
                        HOUSE_SELL: ['HAS_PHOTOS'],
                        HOUSE_RENT: [
                            'IS_PET_FRIENDLY',
                            'IS_PRIVATE_OWNER',
                            'HAS_PHOTOS',
                            'INTERNET',
                            'CABLE_TELEVISION',
                        ],
                        ROOM_RENT: ['IS_PET_FRIENDLY'],
                    },
                },
            },
        },
        terrainArea: {
            default: {
                fieldType: 'range',
                only: ['HOUSE_RENT', 'HOUSE_SELL'],
                payload: {
                    defaultValue: '',
                },
            },
        },
        roofType: {
            default: {
                fieldType: 'multiSelect',
                payload: {
                    defaultValue: [],
                    options: {
                        HOUSE_SELL: ['FLAT', 'DIAGONAL'],
                    },
                },
            },
        },
        investmentState: {
            default: {
                fieldType: 'select',
                only: ['INVESTMENT_SELL'],
                payload: {
                    defaultValue: null,
                    options: {
                        INVESTMENT: ['NOT_STARTED', 'IN_BUILDING', 'READY'],
                    },
                },
            },
        },
        media: {
            default: {
                fieldType: 'multiCheckbox',
                payload: {
                    defaultValue: [],
                    options: {
                        FLAT_RENT: ['INTERNET', 'CABLE_TELEVISION', 'PHONE'],
                        STUDIO_FLAT_RENT: ['INTERNET', 'CABLE_TELEVISION', 'PHONE'],
                        HOUSE_RENT: [
                            'CABLE_TELEVISION',
                            'CESSPOOL',
                            'ELECTRICITY',
                            'GAS',
                            'INTERNET',
                            'PHONE',
                            'SEWAGE',
                            'WATER',
                            'WATER_PURIFICATION',
                        ],
                        ROOM_RENT: ['INTERNET', 'CABLE_TELEVISION', 'PHONE'],
                    },
                },
            },
        },
        plotType: {
            default: {
                fieldType: 'multiSelect',
                only: ['TERRAIN_SELL', 'TERRAIN_RENT'],
                payload: {
                    defaultValue: [],
                    options: {
                        DEFAULT: [
                            'BUILDING',
                            'AGRICULTURAL',
                            'RECREATIONAL',
                            'COMMERCIAL',
                            'WOODLAND',
                            'AGRICULTURAL_BUILDING',
                            'HABITAT',
                            'OTHER',
                        ],
                    },
                },
            },
        },
        vicinity: {
            default: {
                fieldType: 'multiCheckbox',
                only: ['TERRAIN_SELL'],
                payload: {
                    defaultValue: [],
                    options: {
                        DEFAULT: ['FOREST', 'LAKE', 'OPEN_TERRAIN', 'MOUNTAINS', 'SEA'],
                    },
                },
            },
        },
        useTypes: {
            default: {
                fieldType: 'multiCheckbox',
                payload: {
                    defaultValue: [],
                    options: {
                        COMMERCIAL_PROPERTY: ['GASTRONOMY', 'HOTEL', 'INDUSTRIAL', 'OFFICE', 'RETAIL', 'SERVICES'],
                        HALL: ['STOCK', 'MANUFACTURING', 'OFFICE', 'COMMERCIAL'],
                    },
                },
            },
        },
        structure: {
            default: {
                fieldType: 'multiSelect',
                payload: {
                    defaultValue: [],
                    options: {
                        HALL: ['STEEL', 'BRICK', 'SHED', 'TENT', 'WOOD', 'GLASS'],
                    },
                },
            },
        },
        heating: {
            default: {
                fieldType: 'multiSelect',
                payload: {
                    defaultValue: [],
                    options: {
                        FLAT_RENT: ['URBAN', 'GAS', 'TILED_STOVE', 'ELECTRICAL', 'BOILER_ROOM', 'OTHER'],
                        STUDIO_FLAT_RENT: ['URBAN', 'GAS', 'TILED_STOVE', 'ELECTRICAL', 'BOILER_ROOM', 'OTHER'],
                        HALL: ['YES', 'NO'],
                    },
                },
            },
        },
        peoplePerRoom: {
            default: {
                fieldType: 'multiSelect',
                payload: {
                    defaultValue: [],
                    options: {
                        ROOM_RENT: ['ONE', 'TWO', 'THREE'],
                    },
                },
            },
        },
        hasDiscount: {
            default: {
                fieldType: 'checkbox',
                payload: {
                    defaultValue: false,
                },
            },
        },
        /* eslint-enable @typescript-eslint/naming-convention */
    },
    shouldAlwaysDisplayToast: true,
    shouldIgnoreMarketSegmentLogs: true,
    urlSegments: {
        possibleSegments: {
            transaction: [
                {
                    label: 'comprar',
                    value: TRANSACTION.sell,
                },
                {
                    label: 'arrendar',
                    value: TRANSACTION.rent,
                },
            ],
            estate: [
                {
                    label: 'apartamento',
                    value: ESTATE.flat,
                },
                {
                    label: 't0',
                    value: ESTATE.studioFlat,
                },
                {
                    label: 'moradia',
                    value: ESTATE.house,
                },
                {
                    label: 'quarto',
                    value: ESTATE.room,
                },
                {
                    label: 'terreno',
                    value: ESTATE.terrain,
                },
                {
                    label: 'imoveis-comerciais',
                    value: ESTATE.commercialProperty,
                },
                {
                    label: 'armazens',
                    value: ESTATE.hall,
                },
                {
                    label: 'garagem',
                    value: ESTATE.garage,
                },
                {
                    label: 'empreendimento',
                    value: ESTATE.investment,
                },
            ],
            market: [
                {
                    label: 'nova-construcao',
                    value: 'PRIMARY',
                },
                {
                    label: 'casas-usadas',
                    value: 'SECONDARY',
                },
            ],
            roomsNumber: [
                {
                    label: 't1',
                    value: 'TWO',
                },
                {
                    label: 't2',
                    value: 'THREE',
                },
                {
                    label: 't3',
                    value: 'FOUR',
                },
                {
                    label: 't4',
                    value: 'FIVE',
                },
            ],
            useTypes: [
                {
                    label: 'escritorio',
                    value: 'OFFICE',
                },
            ],
        },
    },
};
